/**
 * # Not Found
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/variables" as *;
@use "../../styles/abstracts/borders" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/mediaqueries" as *;
@use "../../styles/abstracts/utilities" as *;

/**
 * ## Styles
 */
.not-found {
    min-height: calc(100vh - 370px);
    color: $white;
    padding-top: 200px;
    text-align: center;
}

.centerwrapper {
    @extend %centerwrapper;
}

.headline {
    font-size: 52px;
    line-height: 58px;
    padding-right: 0;
}

.link {
    color: $marketing-blue-gray-100;
}