/**
 * # Page Header
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/variables" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
.page-header {
    background: transparent;
    border-bottom: 1px solid transparent;
    padding-bottom: $spacing * 2;
    position: fixed;
    transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
    width: 100%;
    z-index: 6;

    &--scrolled {
        background-color: $blue-gray-900;
        border-color: rgba($white, .05);
    }

    &--scrolled-infinity {
        background: $white;
    }

    &--infinity {
        padding-bottom: 0;
    }

    &__body {
        color: $white;
        display: flex;
        justify-content: space-between;
        max-width: $screen-min-md-lg;
        padding: ($spacing * 6) ($spacing * 4) ($spacing * 4);
        width: 100%;
        @include center-x;

        :global(.infinity) & {
            color: $codGrey;
        }

        .page-header--infinity & {
            max-width: calc(1360px + ($spacing * 8));
        }
    }

    &__menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.hidden-headline {
    @extend %sr-only;
}

:global(.page-header) a.infinity-menu_cta-button {
    margin-bottom: 6px;
    margin-left: $spacing * 9;
    
    @include minSm {
        margin-bottom: 0;
        margin-left: ($spacing * 6);
    }

    @include maxXXsm {
        margin-left: 0;
    }

   &::before {
        display: none;
    }

    & span {
        padding: 10px 14px;
        font-size: 14px;
        font-weight: 400;

        @include maxXXsm {
            padding: 5px;
        }
    }
}

.infinity-menu {
    height: 52px;

    & li {
        padding-top: 0;
        display: flex;
        align-items: center;
    }
}

.grow {
    flex-grow: 1;
}

.hidden {
    display: none;
}