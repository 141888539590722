/**
 * # Infinity About Page Content Styles
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/fonts" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
 .about-us {
    font-family: $font-family-infinity;
    -webkit-font-smoothing: antialiased;
    @include minSm {
        padding-top: 0;
    }
 }

 .about-teaser-wrapper {

    & :global(.centerwrapper)>div>div {
        &:first-child {
            width: 55%;

            @include maxSm {
                width: 100%;
            }
        }

        &:last-child {
            width: 45%;

            @include maxSm {
                width: 100%;
            }

            & p {
                text-wrap: wrap;
                width: 100%;
            }
        }
    }

    @include minSm {
        padding-bottom: 187px;
    }
 }

 .quinary {
    text-align: center !important;
    padding-bottom: 25px;
    margin-bottom: 41px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
 }

 .leadership {
    position: relative;
    z-index: 0;
    padding-top: 35px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 499px;
        z-index: -1;
        background: linear-gradient(180deg, #EBEDFF 4.24%, rgba(235, 237, 255, 0) 100.05%);
        transform: translateY(-11%);

        @include maxSm {
            transform: translateY(5%);
        }
    }
 }

 .leaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: $spacing * 3;

    @include maxXsm {
        flex-direction: column;
    }
 }

 .leader {
    width: 33.33%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;

    @include maxSm {
        width: 50%;
    }

    @include maxXsm {
        width: 100%;
    }

    &-info {
        margin-left: 12px;
    }

    &-name {
        font-size: 18px;
        line-height: 22.5px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 5px;
        color: $codGrey;
    }

    &-role {
        font-size: 14px;
        line-height: 17.5px;
        font-weight: 500;
        color: $codGrey;
        opacity: 50%;
        margin: 0;
    }

    &-avatar {
        width: 67px;
        height: 67px;
        border-radius: 100%;
    }
 }

 .team {

    &-quinary {
        @extend %headline-quinary;
    }

    &-headline {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        color: $codGrey;
        max-width: 285px;
        text-wrap: pretty;
        margin: 0 auto;

        @include maxSm {
            margin-bottom: 20px;
            text-wrap: balance;
        }

        @include minSm {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 50px;
        }
    }
 }

 .map {
    @extend %centerwrapper;
    max-width: 944px;

    @include maxSm {
        padding-left: 0;
        padding-right: 0;
    }
    
    & button {
        color: $codGrey;
        font-family: $font-family-infinity;
        position: relative;

        & span {
            opacity: 60%;
        }

        &:hover,
        &:global(.tempest-map__button--active) {
            background-color: $fuchsiaBlue;
            opacity: 1;
            color: $white;
        }
    }

    & :global(.tempest-map__pin) {
        width: 8px;
        height: 8px;
    }

    & :global(.tempest-map__pin--product) {
        box-shadow: 0 0 0 8px rgba(129, 189, 249, 0.1);
    }

    & :global(.tempest-map__body) {
        margin-bottom: 24px;
    }

    & :global(.tempest-map__legend-list) {
        margin-bottom: 65px;

        @include maxSm {
            margin-bottom: 35px;
            width: 100%;
            justify-content: flex-start;
            overflow-x: auto;
            flex-wrap: unset;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    /**
      * ## Engineering 🛠️
      */
    :global(.tempest-map__pin--engineering) {
        box-shadow: 0 0 0 8px rgba($marketing-purple-300, .1);
    }

    /**
      * ## Product 📦
      */
    :global(.tempest-map__pin--product) {
        box-shadow: 0 0 0 8px rgba($marketing-turquoise-100, .1);
    }

    /**
      * ## Marketing 📺
      */
    :global(.tempest-map__pin--marketing) {
        box-shadow: 0 0 0 8px rgba($marketing-pink-100, .1);
    }

    /**
      * ## Design 🖌️
      */
    :global(.tempest-map__pin--design) {
        box-shadow: 0 0 0 8px rgba($marketing-green-100, .1);
    }

    /**
      * Finance 💸
      */
    :global(.tempest-map__pin--finance) {
        box-shadow: 0 0 0 8px rgba($marketing-blue-450, .1);
    }

    /**
      * ## Operations 🔬
      */
    :global(.tempest-map__pin--operations) {
        box-shadow: 0 0 0 8px rgba($marketing-purple-50, .1);
    }
 }

 .boxed-content {
    max-width: 602px;
    padding: 52px 58px;
    border: 2px solid rgba($color: $black, $alpha: 0.07);
    border-radius: 16px;
    margin: 0 auto;
    background-color: $whitePointer;
    margin-bottom: 116px;

    @include maxSm {
        padding: 24px 16px;
        margin-bottom: 40px;
        margin-left: 16px;
        margin-right: 16px;
    }

    @include minXsmMaxSm {
        margin-left: auto;
        margin-right: auto;
    }

    &-text {
        font-size: 18px;
        line-height: 26.01px;
        font-weight: 400;
        color: $black;
        text-align: center;
        margin: 0;

        @include maxSm {
            font-size: 16px;
            line-height: 26.24px;
        }
    }
 }
