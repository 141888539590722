/**
 * # About Page Content Styles
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
.text-vault {
    @extend %ellipse-background;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &__intro {
        @extend %container-intro;
        padding-bottom: 0;
        z-index: 6;
    }

    :global(.illustration-text-vault) {
        margin-left: -80px;
        width: 100%;

        @include minSm {
            margin-left: -100px;
            max-width: 500px;
        }
    }
}

.ladyfingers {
    @include ladyfingers-page-top();
}