/**
 * # Custom Browser Solutions
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;
 @use "../../styles/abstracts/fonts" as *;

/**
 * ## Styles
 */
.card {
    font-family: $font-family-infinity;
    background: $whiteLilac;
    padding: 9px 32px 0;
    border-radius: 16px;
    margin-bottom: 16px;
    position: relative;
    z-index: 0;

    @include maxSmMd {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2px;
        border-radius: inherit;
        background: linear-gradient(180deg, #AA6FDB 0%, rgba(170, 111, 219, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
        -webkit-mask: linear-gradient($whiteLilac 0 0) content-box, linear-gradient($whiteLilac 0 0);
        mask: linear-gradient($whiteLilac 0 0) content-box, linear-gradient($whiteLilac 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        z-index: -1;
        box-shadow: none;

        @include maxSm {
           background: linear-gradient(230.46deg, #AA6FDB 1.95%, rgba(170, 111, 219, 0) 99.95%), linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
        }
    }
}

.title {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    font-weight: 500;
    color: $black;
    margin: 0;

    @include minSm {
    }
}

.description {
    font-size: 14px;
    line-height: 17.5px;
    font-weight: 300;
    text-align: center;
    color: $black;
    opacity: 60%;
    margin: 5px 0 10px;
}

.image {
    width: 100%;
 }