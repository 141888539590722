/**
 * # Illustration Right Overlap
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/mediaqueries" as *;
@use "../../styles/abstracts/utilities" as *;

/**
 * ## Styles
 */
.setup {
    color: $white;
    max-width: $regular-content-width;
    @include center-x;
    z-index: 2;

    @include minSm {
        display: flex;
        gap: 70px;
    }

    &__text {
        position: relative;
        max-width: 477px;

        @include minSm {
            width: 50%;
        }
    }

    &__headline-inconsistent {
        @extend %headline-inconsistent;
    }

    &__image-wrapper {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        margin: 0;

        @include minSm {
            width: 50%;
        }

        @include minMdLg {
            margin-top: -60px;
        }
    }

    &__image {
        flex-shrink: 0;
        width: 110%;

        @include minSm {
            margin-bottom: -200px;
            position: relative;
        }

        @include minSmMd {
            width: 640px;
        }
    }
}