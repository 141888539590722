/**
 * # Promo Box
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles//abstracts/measures" as *;
@use "../../styles//abstracts/mediaqueries" as *;
@use "../../styles//abstracts/utilities" as *;

/**
 * ## Styles
 */
.promo-box {
    color: $white;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing * 12;
    max-width: $regular-content-width;
    @include center-x;

    @include minSmMd {
        display: grid;
        column-gap: $spacing * 10;
        grid-template-columns: 330px auto;
        grid-template-rows: auto auto;
    }

    &__text {
        order: 1;
        width: 100%;

        @include minSmMd {
            align-self: start;
            grid-column: 1;
            max-width: 330px;
            min-width: 330px;
        }

        :global(.headline-quaternary) {
            margin-bottom: $spacing * 5;
        }
    }

    &__cta {
        display: flex;
        justify-content: center;
        order: 3;
        width: 100%;

        @include minSmMd {
            align-self: start;
            grid-column: 1;
            grid-row: span 2;
            justify-content: flex-start;
        }
    }

    &__usp {
        order: 2;
        margin-bottom: $spacing * 10;
        width: 100%;

        @include minSmMd {
            grid-column: 2;
            grid-row: 1 / span 2;
        }
    }
}