/**
 * # Measures & Spacings
 */
$measure: 4px;
$spacing: $measure;

$breakpoint-md: 992px;
$breakpoint-l: 992px;
$breakpoint-xl: 1200px;

$appheader-height-mobile: 76px;
$appheader-height: 124px;

$appfooter-height-mobile: 200px;
$appfooter-height: 99px;

$regular-content-width: 1100px;

$centerwrapper-padding: $spacing * 4;
$centerwrapper-padding-minSmMd: $spacing * 20;