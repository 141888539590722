/**
 * # Custom Features
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;

 .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &--desktop {
        @include maxSm {
            display: none;
        }
    }

    &--mobile {
        display: none;

        @include maxSm {
            display: flex;
            flex-direction: column;
            gap: unset;
        }
    }
 }

 .column {
    flex: 45%;
    max-width: 45%;
    max-width: 350px;

    @include maxSm {
        margin-left: auto;
        margin-right: auto;
    }
 }

 .pink-border {
    &::before {
        background: linear-gradient(180deg, #EF60AC 0%, rgba(239, 96, 172, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
        rotate: 180deg;

        @include maxSm {
            background: linear-gradient(270deg, #EF60AC 3.14%, rgba(239, 96, 172, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
        }
    }
 }

 .new-tab {
    padding-left: 22px;
    padding-right: 22px;

    & p {
        margin-bottom: 10px;
    }

    &::before {
        rotate: 0deg;
    }
 }

 .default-search {
    padding-left: 20px;
    padding-right: 20px;

    & h3 {
        width: 90%;
        margin: 0 auto;
    }

    & p {
        margin-bottom: 0;
    }
 }

 .widgets {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    & p {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 0;
    }

    & img {
        width: 95%;
    }
 }

 .privacy-panel {
    text-align: center;
    padding-bottom: 15px;
    & img {
        width: 75%;
    }

    @include maxSm {
        &::before {
            rotate: 180deg;
        }
    }

 }