/**
 * # Mobile Menu
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/borders" as *;
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/fonts" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/variables" as *;

 /**
  * ## Styles
  */
 .mobile-menu {
     max-width: $screen-min-md-lg;
     width: 100%;
     z-index: 10;
     @include center-x(relative);

     &--infinity {
        & .navlist {
            background-color: $white;
            color: $codGrey;
            width: 100%;
            padding-top: 20px;
        }
     }

     &--hidden {
         @extend %sr-only;
     }
 }

 .navlist {
    background-color: $blue-gray-300;
    border-radius: $border-radius-l;
    box-shadow: 0 1px 5px 2px rgba($black, .25);
    font-size: 16px;
    right: $spacing * 6;
    max-width: 400px;
    padding: ($spacing * 3) $spacing ($spacing * 4);
    position: fixed;
    top: $spacing * 25;
    width: calc(100% - ($spacing * 12));
    z-index: 1;

    .mobile-menu--infinity & {
        max-width: 100%;
    }
 }

 .list-item {
    margin: 0 ($spacing * 7);
    min-width: calc(100% - ($spacing * 14));
    display: flex;
    align-items: center;

    &:nth-of-type(n + 2) {
        border-top: 1px solid $gray-600;
    }
 }

 .list-link {
    color: $white;
    display: inline-block;
    padding: ($spacing * 6) ($spacing * 4) ($spacing * 5);
    width: 100%;

    &--disabled {
        color: $gray-300;
    }
 }

 .expand-button {
    position: relative;
    border: none;
    background: none;
    padding: 0;
    margin: 0;

    &__icon {
        transform: rotate(-180deg);

        &--open {
            transform: rotate(0);
        }
    }
 }

 .children-menu__item {
    margin: 0 ($spacing * 7);
    min-width: calc(100% - ($spacing * 14));
    display: flex;
    align-items: center;
    display: none;

    &--open {
        display: flex;
        align-items: center;
    }


    &-link {
        font-size: 16px;
        line-height: 66px;
        font-weight: 400;
        color: $white;
        width: 100%;
        padding-left: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__icon {
            transform: rotate(-90deg);
        }
    }
}

.mobile-menu--infinity .list-link {
    color: $codGrey;
    font-family: $font-family-infinity;
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
}

.mobile-menu--infinity .list-item {
    border-top: 0;
    width: 100%;
    float: left;
    min-width: 40%;
    margin: 0;
    padding-left: 28px;
    padding-right: 28px;

    &:last-child {
        justify-content: center;
    }
}

.mobile-menu--infinity .navlist {
    right: 0;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.mobile-menu--infinity .infinity-menu_cta-button {
    float: right;
    margin-bottom: 22px;
}