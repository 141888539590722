/**
 * # Utilities
 */
/**
 * ## Imports
 */
@use "./measures" as *;
@use "./fonts" as *;
@use "./colors" as *;
@use "./mediaqueries" as *;

/**
* ## Sass placeholders
*/
%no-underline {
    text-decoration-line: none;
}

/**
 * Use sr-only to hide an element visually without hiding it from
 * screen readers.
 */
%sr-only:not(:focus):not(:active) {
    border-width: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

%not-sr-only {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: static;
    white-space: normal;
    width: auto;
}

/**
* %button-as-link resets a `<button>` element's
* look and feel to look like a simple link.
*/
%button-as-link {
    background-color: transparent;
    border: none;
    font-size: 1em;
    line-height: 1;
    padding: 0;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

/**
 * @mixin center-x
 * @mixin center-y
 * @mixin center-xy
 */
@mixin center-x($position: relative) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-y($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-xy($position: relative) {
    position: $position;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/**
 * #### Ellipse in Background
 */
 %ellipse-background {
    background: radial-gradient(ellipse at bottom,
            $blue-gray-200 30%,
            $blue-gray-700 60%,
            $blue-gray-1000 70%);
}

@mixin cssAnimation($animationName,
    $animationDelay: 0,
    $animationDuration: 1s,
) {
    animation: $animationName;
    animation-delay: $animationDelay;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideHalfRightToLeft {
    0% {
        opacity: 0;
        transform: translateX(5%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideHalfLeftToRight {
    0% {
        opacity: 0;
        transform: translateX(-5%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 15%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

%cta-paragraph {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: $spacing * 3;

    .advantages-screen & {
        opacity: 0;
        @include cssAnimation(fadeInUp, .4s, 1.25s);

        @include maxSmMd {
            margin-inline: auto;
            max-inline-size: 25ch;
            /**
              * - Not supported by Safari/Firefox yet.
              * Feel free to replace it with `padding`
              * if needed:
              */
            text-wrap: balance;
        }
    }

    /**
      * - Element selector not ideal but it comes
      * via translation string:
      */
    strong {
        font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    }
}

/*
 * ## Containers
 */
%container-hero {
    max-width: 660px;
    padding: ($spacing * 48) ($spacing * 4) ($spacing * 20);
    position: relative;
    z-index: 2;
    @include center-x;

    @include minSm {
        padding: 317px ($spacing * 4);
    }
}

%container-left-indented {
    max-width: 540px;
    @include center-x;
}

%container-center {
    max-width: 540px;
    text-align: center;
    width: calc(100% - ($spacing * 10));
    @include center-x;
}

%container-intro {
    color: $white;
    padding: 136px ($spacing * 5) ($spacing * 15);
    @include center-x;

    @include minSm {
        padding: 217px ($spacing * 4) $spacing * 20;
    }
}

/**
* Truncate text strings.
* @param {number} $lineCount.
*/
@mixin truncate($lineCount: 2) {
    /**
    * These styles truncate the text string on
    * a specified line.
    */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
    /**
    * Necessary to keep the line-clamp working
    * inside a flex layout as well.
    */
    flex-grow: 0;
}

/**
 * ## Utility classes
 */
/**
 * ### Typographic Aid
 */
.sr-only {
    @extend %sr-only;
}

.forbidden:hover {
    cursor: not-allowed;
}

.no-underline {
    @extend %no-underline;
}

.headline-primary {
    @extend %headline-primary;
}

.headline-secondary {
    @extend %headline-secondary;
}

.headline-tertiary {
    @extend %headline-tertiary;
}

.headline-quaternary {
    @extend %headline-quaternary;
}

.headline-quinary {
    @extend %headline-quinary;
}

.paragraph-lean {
    @extend %paragraph-lean;
}

.headline-lean {
    @extend %paragraph-lean;
    margin: ($spacing * 2) 0;
}

.headline-sober {
    font-family: 'Poppins Light', Helvetica, Arial, sans-serif;
}

.typographic-list {
    list-style: disc;
    padding-left: $spacing * 4;
}

.typographic-list--roman {
    list-style-type: upper-roman;
}

.legal-date {
    @extend %paragraph-lean;
}

.typographic-link {
    color: $white;

    @include minSm {
        color: rgba($white, .7);
    }
}

.allcaps {
    text-transform: uppercase;
}

.copytext-link {
    color: $cerulean-400;
    white-space: nowrap;
}

/**
 * ### Containers
 */
.centerwrapper {
    @extend %centerwrapper;
}

.container-hero {
    @extend %container-hero;
}

.container-left-indented {
    @extend %container-left-indented;
}

.container-center {
    @extend %container-center;
}

.container-intro {
    @extend %container-intro;
}

.illustration-overlap-spacer {
    padding-bottom: ($spacing * 40);

    @include minSm {
        background:
            radial-gradient(50% 50% at 50% 50%,
                #22385F 0%,
                #2E4B7D 0.01%,
                #10151D 100%);
        overflow: hidden;
        padding-top: ($spacing * 40);
        padding-bottom: ($spacing * 50);
    }
}

/**
 * ### Common Center Wrapper
 */
%centerwrapper {
    margin: 0 auto;
    max-width: calc(1100px + $centerwrapper-padding * 2);
    padding-left: $centerwrapper-padding;
    padding-right: $centerwrapper-padding;
    width: 100%;

    @include minSm {
        /**
          * - This calculation is just a spontaneous impression
          * of good space distribution. No design provided: feel
          * free to change it later on:
          */
        width: calc(100% - $centerwrapper-padding-minSmMd);
    }
}

/**
 * ### Copytext Page
 */
%center-body {
    max-width: 734px;
    @include center-x;
}

.copytext-page {
    @extend %center-body;
    @extend %ellipse-background;
    background-position: center center;
    color: $white;
    padding-bottom: ($spacing * 50);
    padding-top: ($spacing * 40);
    width: 100%;

    @include maxSm {
        padding-left: ($spacing * 6);
        padding-right: ($spacing * 6);
    }

    @include minSm {
        color: rgba($white, .7);
    }

    a {
        color: $cerulean-400;
        cursor: pointer;
    }

    &--infinity {
        background: transparent;

        @include maxSm {
            padding-left: ($spacing * 2);
            padding-right: ($spacing * 2);
            color: rgba($white, .7);
            padding-top: ($spacing * 30);
            padding-bottom: ($spacing * 10);

            .centerwrapper {
                padding-left: ($spacing);
                padding-right: ($spacing);
            }
        }
    }
}

.headline-wrapper {
    margin-bottom: ($spacing * 20);
}

.headline-main {
    font-size: 36px;
    font-weight: 600;
    line-height: 46px;
    color: $white;
    margin-bottom: 0;
    text-align: center;

    @include minSm {
        font-size: 52px;
        line-height: 58px;
        text-align: left;
    }
}

.headline-exception-sextary {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    padding-left: ($spacing * 5);
    padding-right: ($spacing * 5);
    max-width: 358px;
    margin: 0 auto;


    @include minSm {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        max-width: none;
        margin: 16px 0;
    }
}

.headline-exception-secondary {
    font-size: 36px;
    font-weight: 600;
    line-height: 46px;
    color: $white;

    @include minSm {
        font-size: 44px;
        line-height: 58px;
    }
}

.headline-exception-quintary {
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    color: $white;
}

.copy-list {
    list-style: disc;
    padding-left: $spacing * 4;

    &--ordered {
        list-style: none;
    }

    &--roman {
        list-style-type: none;
        counter-reset: roman-counter;
    }

    &--box {
        background-color: rgba($white, .05);
        border-radius: 8px;
        margin: ($spacing * 8) $spacing;
        padding: ($spacing * 4) ($spacing * 6);

        @include minSm {
            margin: ($spacing * 10) ($spacing * -15);
            padding: ($spacing * 10) ($spacing * 15);
        }
    }

    &__item {
        margin-bottom: $spacing;

        .copy-list--ordered & {
            &::before {
                content: counter(list-item) ". ";
                display: inline;
                font-size: 20px;
                line-height: 1.5;
                font-weight: bold;
                float: left;
                margin-right: 5px;
            }
        }

        .copy-list--box & {
            margin-bottom: $spacing * 16;
        }

        .copy-list--roman & {
            counter-increment: roman-counter;
            margin-bottom: $spacing * 3;

            &::before {
                content: counter(roman-counter, upper-roman) ". ";
                margin-right: 0.5em; /* Optional: Adjust as needed */
            }
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, .08);
    margin-top: ($spacing * 10);
}


/**
 * ### Button List
 */
.button-list {

    @include minSm {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing * 2;
    }
}

/**
 * ### Deco Elements
 */
/**
 * #### Knight Rider Like Animation 🚔
 */
.deco-knight-rider {
    background-color: rgba($white, .1);
    height: 1px;
    margin: ($spacing * 12) ($spacing * 4) ($spacing * 9);
    max-width: 1100px;
    overflow: hidden;
    position: relative;
    width: calc(100% - $spacing * 8);

    @include minSm {
        margin-bottom: 70px;
        margin-top: 70px;
    }

    @include minMd {
        @include center-x;
    }

    .copytext-page & {
        margin: ($spacing * 4) 0 ($spacing * 6);
        width: 100%;
    }

    &::before,
    &::after {
        animation: moveKnightRider 36s linear infinite;
        background: repeating-linear-gradient(to right,
                transparent,
                #e6c0ff 20%,
                #85629c 30%,
                transparent 100%);
        content: "";
        height: 100%;
        position: absolute;
        width: 100%;
    }

    &::after {
        right: 0;
        transform: scale(-1);
    }
}

@keyframes moveKnightRider {

    0%,
    100% {
        transform: translateX(-50%);
    }

    20% {
        transform: translateX(100%);
    }

    50% {
        transform: translateX(-50%);
    }

    80% {
        transform: translateX(100%);
    }
}

/**
 * #### Ladyfingers 💅
 */
@mixin ladyfingers($background: linear-gradient(140.5deg, #7E64DC 1.35%, #A159EB 27.38%, #E959A6 69.64%, #F55196 92.98%),
    $barHeight: 122px,
    $barLength: 1114px,
    $boxShadow: 0 0 44px 0 $black,
    $rotation: 13deg,
    $opacity: 1,
) {
    /* This `height` actually needs a calc() value to
     * be as flexible as the `$barHeight` value. */
    height: 260px;
    position: relative;
    width: calc($barLength + 14%);
    z-index: 6;

    &::before,
    &::after {
        background: $background;
        border-radius: calc($barHeight / 2);
        box-shadow: $boxShadow;
        content: "";
        display: block;
        height: $barHeight;
        opacity: $opacity;
        position: absolute;
        transform: rotate($rotation);
        width: $barLength;
        z-index: 1;
    }

    &::before {
        z-index: 2;
        left: 0;
        top: calc($barHeight / 2);
    }

    &::after {
        left: 0;
        transform: translateX(20%) rotate($rotation);
    }
}

@mixin ladyfingers-page-top {
    @include ladyfingers(null,
        160px,
        1300px,
    );
    height: 260px;
    position: absolute;
    right: -20%;
    top: -74px;

    @include maxMd {
        display: none;
    }
}

.ladyfingers {
    @include ladyfingers;
}

/**
 * #### Interaction Arrow for Button Designs.
 */
@mixin interaction-arrow($background-color: rgba($black, .3),
    $background-image-1: "../../images/infinity/icons/arrow-right.svg",
    $background-image-2: "../../images/infinity/icons/arrow-right.svg",
    $background-position: (8px center, -22px center),
    $background-size: 23px 23px,
    $dimension: 39px,
    $background-position-hover: (39px center, 9px center),
) {
    &::before {
        background-color: $background-color;
        background-image:
            url($background-image-1),
            url($background-image-2);
        background-position: $background-position;
        background-size: $background-size;
        background-repeat: no-repeat;
        border-radius: 100%;
        content: "";
        display: block;
        height: $dimension;
        transition: background-position .2s ease-in-out;
        width: $dimension;
        animation-delay: 800ms;
        z-index: 4;
    }

    &:hover::before {
        background-position: $background-position-hover;
    }
}