/**
 * # Cookie Consent Banner
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
* ## Styles
 */
.banner {
    background-color: $gray-1000;
    box-shadow: 0 1px 5px 2px rgba($black, .25);
    bottom: 0;
    border-top: 1px solid rgba($white, .05);
    color: $gray-400;
    left: 0;
    padding: ($spacing * 4) ($spacing * 4);
    position: relative;
    width: 100%;
    z-index: 7;
    @include center-x(fixed);

    @include maxSm {
        text-align: center;
    }

    :global(.button-list) {
        flex-shrink: 0;
        flex-wrap: nowrap;
        margin-top: $spacing * 4;
    }

    &__body {
        @extend %center-body;

        @include minSm {
            display: flex;
            gap: $spacing * 8;
            padding-right: $spacing * 12;
        }
    }
}

.copytext {
    margin: ($spacing * 8) 0;

    @include minSm {
        margin: ($spacing * 2) 0;
    }
}