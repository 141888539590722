/**
 * # CTA Teaser Intro
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;
 @use "../../styles/abstracts/fonts" as *;

/**
 * ## Styles
 */
.cta-teaser-intro {
    font-family: $font-family-infinity;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 0;
    padding-right: 0;

    & :global(.headline-quinary) {
        @extend %headline-quinary;

        text-align: left;
    }

    @include minSmMd {
        padding-top: $spacing * 40;
    }

    @include maxSmMd {
        margin-top: ($spacing * 27);
    }

    @include maxSm {
        flex-direction: column;
        margin-top: $spacing * 20;
    }

    &>div {
        width: 50%;
        padding-left: $spacing * 8;

        @include maxSm {
            width: 100%;
            padding-left: 0;
        }

        & :global(.intro-text) {
            width: 100%;
            text-wrap: pretty;

            @include maxSm {
                width: 100%;
            }
        }
    }
}

.headline-main {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: $codGrey;
    margin-top: ($spacing * 4);
    margin-bottom: $spacing * 5;
    max-width: 60%;

    @include maxSm {
        text-align: left;
    }

    @include minSm {
        font-size: 32px;
        line-height: 40px;
    }

    &-about {
        max-width: 85%;

        @include maxSm {
            max-width: 100%;
        }
    }
}

.button-wrapper {
    padding-left: 0;
    text-align: left;

    & a {
        margin-bottom: 0;
    }
    .cta-teaser-intro--infinity & {
        @include minSmMd {
            margin-top: $spacing * 5;
        }
    }
}