/**
 * # Page Footer
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/fonts" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Component Output
 */
.page-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: ($spacing * 25) ($spacing * 4) ($spacing * 5);
    position: relative;

    @include maxSmMd {
        padding-bottom: 160px;
        padding-top: 100px;
    }

    @include minMdLg {
        padding-bottom: 20px;
    }

    &__ladyfingers {
        @include ladyfingers(
            linear-gradient(140.5deg, #7E64DC 1.35%, #A159EB 27.38%, #E959A6 69.64%, #F55196 92.98%),
            122px,
            1114px,
        );
        bottom: 0;
        left: -770px;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        height: 200px;
        width: 1400px;

        &::before {
            top: 40px;
        }

        &::after {
            top: 20px;
        }
    }

    &__ladyfingers-2 {
        @include ladyfingers(
            linear-gradient(140.5deg, #7E64DC 1.35%, #A159EB 27.38%, #E959A6 69.64%, #F55196 92.98%),
            122px,
            1114px,
        );
        pointer-events: none;
        position: absolute;
        right: -470px;
        top: -90px;

        @include maxSmMd {
            right: -500px;
        }

        @include minSmMd {
            top: -30px;
        }
    }
}

.infinity-footer {
    background-color: $codGrey;
    padding-top: 64px;
    padding-bottom: 37px;
    display: flex;

    @include maxSm {
        padding-top: 24px;
        padding-bottom: 24px;
        justify-content: space-between;
    }

    & .logo {
        display: none;
    }
}

.footer-teaser {
    width: 30%;
    font-family: $font-family-infinity;
    color: $white;

    @include maxSm {
        width: 50%;
    }

    &-quinary {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
    }

    &-headline {
        font-size: 48px;
        line-height: 51.98px;
        font-weight: 400;
        max-width: 273px;
        margin-top: 16px;
        margin-bottom: 23px;

        @include maxSm {
            font-size: 32px;
            line-height: 34.66px;
        }
    }

    &-button {
        background-color: $white;

        &::after {
            display: none;
        }
        & span {
            color: $codGrey;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.logo {
    display: flex;
    justify-content: center;
    width: 100%;
}

.legal-nav {
    margin-top: $spacing * 4;

    @include minSm {
        display: flex;
        margin-top: $spacing * 11;
    }

    &__infinity {
        margin-bottom: ($spacing * 6);
    }

    &__item {
        @include maxSm {
            margin-bottom: $spacing * 4;
            text-align: center;
        }

        @include minSm {
            &:nth-of-type(n + 2) {
                margin-left: $spacing;

                &::before {
                    color: rgba($white, .4);
                    content: "| "
                }
            }
        }
    }

    &__link {
        color: rgba($white, .4);
    }

    &__infinity-link {
        color: rgba($white, 1);
        line-height: 34px;

        @include maxSm {
            color: rgba($white, .4);
        }
    }
}

.logo-infinity {
    width: 198px;
}

.legal-links {
    font-family: $font-family-infinity;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 20px;

    &>ul {
        margin-top: 2.5px;
    }

    @include maxSmMd {
        width: 20%;
        padding-top: 50px;
    }

    @include maxSm {
        width: 100%;
        align-items: center;
        padding-left: 0;
        padding-top: 0;
        flex-direction: row-reverse;
        justify-content: space-between;

        &>ul {
            width: 42.5%;
        }
    }

    &--item {
        max-width: 150px;
        display: block;
        width: 100%;
        margin-bottom: 4px;
    }

    &--item-link {
        font-size: 16px;
        line-height: 48px;
        font-weight: 400;
        margin-top: 0;
        text-align: left;
        padding-top: 0;
        color: $white;
    }
}

.social-media {
    display: flex;
    justify-content: space-between;
    max-width: 120px;
    margin-right: 30px;
}

.imprint {
    font-size: 16px;
    line-height: 48px;
    font-weight: 400;
    margin-top: 0;
    text-align: left;
    padding-top: 0;
    color: $white;
}