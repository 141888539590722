/**
 * # CTA Teaser
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/fonts" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;

.cta-teaser-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 2;

    @include minMd {
        max-height: 660px;
    }

    &.cta-teaser-infinity {
        overflow: visible;
        max-height: unset;
    }
}

.cta-teaser {
    padding-top: ($spacing * 13);

    @include minSmMd {
        display: flex;
        gap: $spacing * 4;
        padding-left: 195px;
        position: relative;
    }

    .cta-teaser-infinity & {
        padding-top: ($spacing * 8);
        text-align: center;
        text-wrap: balance;

        @include minSmMd {
            padding: 0;
            text-align: left;
        }
    }

    .cta-teaser-infinity--overlap & {
        @include minSmMd {
            margin-bottom: -370px;
        }
    }

    /**
     * ### Infinity Client:
     */
    .cta-teaser-infinity & {
        text-align: center;

        @include minSmMd {
            display: block;
        }
    }

    &__left {
        position: relative;
        z-index: 2;

        @include minSmMd {
            flex-shrink: 2;
            max-width: $spacing * 129;
            min-height: 660px;
            padding-top: $spacing * 18;
            padding-bottom: $spacing * 10;
            width: 50%;
        }

        .cta-teaser-infinity & {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            opacity: 0;
            @include cssAnimation(fadeIn, .25s, 3s);

            @include maxSmMd {
                margin-bottom: $spacing * 8;
            }

            @include minSm {
                max-width: unset;
                min-height: unset;
                padding-top: $spacing * 5;
                text-align: center;
                @include center-x;
            }

            @include minSmMd {
                width: 80%;
            }
        }
    }

    &__visual {
        position: relative;

        @include minSmMd {
            width: 50%;
        }
    }

    &__visual-source {
        max-width: 100%;
    }

    &__visual-image {
        width: 100%;
        opacity: 0;
        position: relative;
        z-index: 2;
        margin-top: ($spacing * 3);
        @include cssAnimation(slideHalfRightToLeft, .25s, 1.5s);

        @include minSm {
            margin-top: 0;
            width: 106%;
            margin-left: 0;
            width: 100%;
        }

        @include minSmMd {
            left: 0;
            height: auto;
            min-width: 930px;
            position: absolute;
        }

        &__browser {
            margin-top: ($spacing * 8);
            .cta-teaser-infinity & {
                @include minSmMd {
                    position: static;
                    max-width: 772px;
                    min-width: 600px;
                }

                @include maxSm {
                    margin-top: 0;
                }
            }
        }

        .cta-teaser-infinity & {
            @include minSmMd {
                position: static;
                // max-width: 860px;
                // min-width: 600px;
            }
        }
    }
}

:global(.headline-sub) {
    @extend %headline-secondary;

    .cta-teaser__left & {
        margin-bottom: $spacing * 4;
        text-align: center;

        @include minSmMd {
            margin: $spacing 0 ($spacing * 8);
            text-align: left;
        }
    }
}

.button-wrapper {
    .cta-teaser-infinity & {
        @include minSmMd {
            margin-top: $spacing * 5;
        }
    }
}

.intro-paragraph {
    font-family: $font-family-infinity;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $codGrey;
    opacity: 60%;
    text-align: left;

    &:first-child {
        margin-top: $spacing * 10;

        @include maxSm {
            margin-top: 20px;
        }
    }

    &-about {
        max-width: 438px;
    }
}