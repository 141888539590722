/**
 * # Button Burger
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/mediaqueries" as *;
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/utilities" as *;

/**
  * ## Placeholders
  */
%pseudo-element {
    background-color: $white;
    content: "";
    display: block;
    height: calc($spacing / 2);
    position: absolute;
    transition: transform .3s ease-in;
    width: $spacing * 6;
}

/**
  * ## Styles
  */
.burger {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: $spacing * 9;
    justify-content: center;
    margin-top: $spacing * 4;
    overflow: hidden;
    position: relative;
    text-indent: -999rem;
    width: $spacing * 9;

    @include maxSm {
        margin-right: $spacing * 3;
    }

    &--open {

        &::before {
            transform: translateY(8px) rotate(45deg);
        }

        &::after {
            transform: translateY(-8px) rotate(-45deg);
        }
    }

    &::before {
        @extend %pseudo-element;
        top: 9px;
    }

    &::after {
        @extend %pseudo-element;
        bottom: 9px;
    }

    &__body {
        background-color: $white;
        border: 0;
        height: calc($spacing / 2);
        transition: opacity .3s ease-in;
        width: $spacing * 6;

        .burger--open & {
            opacity: 0;
        }
    }

    &-infinity {
        justify-content: flex-end;
        
        &::before, &::after {
            background-color: $black;
            width: 28px;
        }
    }
}

.burger-infinity .burger__body {
    background-color: $black;
}