/**
 * # About Us Section
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Placeholders / Mixins
 */

%text-illustration {
    @include minSm {
        display: flex;
        gap: $spacing * 8;
    }
}

%text-wrapper {
    overflow: hidden;

    @include maxSm {
        padding: 0 ($spacing * 5);
    }

    @include minSm {
        width: 50%;
    }
}

%illustration-wrapper {
    @include minSm {
        width: 50%;
    }
}

/**
 * ## Styles
 */
.about-us {
    color: $white;
    max-width: 100%;
    position: relative;
    z-index: 3;

    &__body {
        @extend %container-left-indented;

        @include maxSm {
            padding: 0 ($spacing * 5);
        }

        @include minSm {
            margin-bottom: 136px;
        }
    }

    &__ellipse {
        @extend %ellipse-background;
        background-position: center bottom;
    }

    :global(.ladyfingers) {
        @include minSm {
            @include center-x(relative);
        }
    }

    &__text-wrapper {
        @extend %text-wrapper;
    }

    &__illustration-wrapper {
        @extend %illustration-wrapper;
    }

    &__headline-inconsistent {
        @extend %headline-inconsistent;
    }

    :global(.paragraph-lean) {
        color: rgba($white, .7);
    }
}
/**
 * ### Phoner
 */
.phoner {
    position: relative;

    &__body {
        @extend %center-body;
    }

    :global(.headline-primary) {
        text-align: center;
    }

    &__inner {
        @extend %text-illustration;
    }

    &__text-wrapper {
        @extend %text-wrapper;

        @include minSm {
            margin-top: 90px;
        }
    }

    &__headline-inconsistent {
        font-size: 32px;
        line-height: 48px;
        margin: 0 0 ($spacing * 8);
        text-align: center;

        @include minSm {
            font-size: 64px;
            line-height: 96px;
            margin: ($spacing * 6) 0;
        }
    }

    &__illustration-wrapper {
        @extend %illustration-wrapper;

        @include minSm {
            display: flex;
            align-items: flex-end;
        }
    }

    &__image {
        height: auto;
        margin: 0 -4%;
        width: 108%;

        @include minSm {
            margin-left: 20px;
            margin-top: 30px;
            max-width: 613px;
            width: auto;
        }
    }

    &__ladyfingers {
        @include ladyfingers();
        bottom: -0%;
        position: absolute;
        left: -800px;
        z-index: 1;

        @include maxMdLg {
            display: none;
        }
    }
}

/**
 * ### Text Vault
 */
.text-vault {
    background-color: $blue-gray-800;
    overflow: hidden;
    position: relative;

    &__ladyfingers {
        @include ladyfingers(
            rgba($white, .1),
            122px,
            1114px,
            none
        );
        position: absolute;
        right: -600px;
        top: 260px;

        @include maxMdLg {
            position: absolute;
            z-index: 1;
        }
    }

    &__body {
        @extend %center-body;
        @include maxMdLg {
            position: relative;
            z-index: 2;
        }
    }

    &__inner {
        @extend %text-illustration;

        @include minSm {
            padding-top: 220px;
        }
    }

    &__text-wrapper {
        @extend %text-wrapper;
        margin-top: $spacing * 11;

        @include minSm {
            margin-top: 137px;
            order: 2;
        }
    }

    &__illustration-wrapper {
        @extend %illustration-wrapper;

        @include minSm {
            order: 1;
        }
    }

    &__image {
        height: auto;
        max-width: 270px;
        margin-left: 9px;
        margin-top: 22px;

        @include minSm {
            max-width: 436px;
            margin-left: -177px;
            margin-top: 0;
        }
    }
}

/**
 * ### Scanner
 */
.scanner {
    background-color: $blue-gray-800;
    margin-top: -60px;
    overflow: hidden;
    position: relative;

    @include maxSmMd {
        padding-bottom: $spacing * 40;
    }

    @include minSm {
        padding-bottom: $spacing * 30;
    }

    &__ladyfingers {
        @include ladyfingers(
            rgba($white, .1),
            122px,
            1114px,
            none
        );
        position: absolute;
        left: -660px;
        top: 70px;

        @include maxSm {
            left: -1000px;
            position: absolute;
            top: 500px;
            z-index: 1;
        }
    }

    &__body {
        @extend %center-body;
        position: relative;
        z-index: 2;
    }

    &__inner {
        @extend %text-illustration;

        @include minSm {
            margin-top: -110px;
        }
    }

    &__text-wrapper {
        @extend %text-wrapper;

        @include minSm {
            margin-top: 300px;
        }
    }

    &__illustration-wrapper {
        @extend %illustration-wrapper;

        @include minSm {
            padding-top: $spacing * 20;
        }
    }

    &__image {
        height: auto;
        margin-bottom: $spacing * 12;
        margin-left: -50px;
        max-width: 277px;
        @include center-x;

        @include minSm {
            max-width: 444px;
            margin-left: 102px;
        }
    }
}