/**
 * # base.scss - basic styles and helper classes.
 */
/**
 * ## Imports
 */
@use './abstracts' as *;

/**
* ## Typography
*/
// Poppins
@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Regular.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Bold";
    src: url("../fonts/Poppins-Bold.ttf") format("truetype");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Semi Bold";
    src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Light";
    src: url("../fonts/Poppins-Light.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Thin";
    src: url("../fonts/Poppins-Thin.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

// LexendDeca

@font-face {
    font-family: "LexendDeca";
    src: url("../fonts/LexendDeca-Regular.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LexendDeca Bold";
    src: url("../fonts/LexendDeca-Bold.ttf") format("truetype");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: "LexendDeca Semi Bold";
    src: url("../fonts/LexendDeca-SemiBold.ttf") format("truetype");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: "LexendDeca Light";
    src: url("../fonts/LexendDeca-Light.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LexendDeca Thin";
    src: url("../fonts/LexendDeca-Thin.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}


/**
  * ## Elements
  */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    &:has(.infinity) {
        scroll-behavior: auto;
    }
}

html,
body {
    height: 100%;
}

html {
    -webkit-text-size-adjust: 100%;
    /* Prevent font scaling in landscape while allowing user zoom */
}

body {
    background-color: $blue-gray-1000;
    font-family: $font-family;
    /* - This property is deprecated. To make it work in Chrome
    * users have to activate it under Chrome://flags. */
    font-size: 16px;
    margin: 0;
    overflow-x: hidden;

    &:has(.infinity) {
        background-color: $white;
    }

    /**
    * This style block prevents the menu from scrolling
    * when its content isn't even long enough to be
    * scrolled.
    */
    &.body-frozen {
        position: fixed;
        overflow: hidden;
        max-height: 100vh;
        width: 100%;
    }
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $gray-900;
}

/* - 🚨: Editors beware! Changing this `overflow-x: hidden;` style on
 * this all wrapping element would cause `.ladyfingers` to create
 * an unwanted horizontal scroll on mobile devices. */
#root {
    overflow-x: hidden;

    &.infinity {
       background-color: $white;
       color: $codGrey;
       -webkit-font-smoothing: antialiased;
    }
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

abbr {
    text-decoration: none;
}

mark {
    background-color: $gray-90;
}