/**
 * # Logo
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
.logo-wrapper {
    flex-shrink: 1;
    margin-top: -2px;
    width: 100%;

    @include maxSm {
        padding-left: $spacing * 2;
    }

    &--76 {
        height: 76px;
    }

    &--61 {
        height: 61px;
    }

    &--infinity {
        height: auto;
        @include maxSm {
            padding-left: 0;
            height: auto;
            & svg {
                max-width: 120px;
            }
        }
    }
}