/**
 * # Button Standard
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;

/**
 * ## Styles
 */
.button {
    background-color: $blue-gray-300;
    border: none;
    border-radius: $spacing * 10;
    color: $white;
    font-family: "Poppins Light", Helvetica, Arial, sans-serif;
    font-style: normal;
    display: inline-block;
    padding: ($spacing * 5) ($spacing * 5) ($spacing * 5) ($spacing * 10);
    position: relative;
    transition: background-color .25s ease-in;

    &:hover {
        background-color: $blue-gray-160;
    }

    &:active {
        background-color: $blue-gray-140;
    }

    &:global(.btn-accept) {
        margin-bottom: $spacing * 6;

        &::before {
            content: "🍪";
            display: block;
            position: absolute;
            left: $spacing * 4;
            top: 18px;
        }
    }
}