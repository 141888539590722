/**
 * ## Colors
 */

/**
 * ### Monochromatics
 */
$white: #FFF;
$black: #000;
$codGrey: #111;
$fuchsiaBlue: #6F55C7;
$whiteLilac: #FAF7FD;
$whitePointer: #FEF9FF;

$gray-70: #F9F9FA;
$gray-80: #F3F4F4;
$gray-90: #EDEEEF;
$gray-100: #E7E9EA;
$gray-200: #E1E3E4;
$gray-300: #C4C6C8;
$gray-400: #A6AAAD;
$gray-500: #888E91;
$gray-600: #6D7275;
$gray-700: #565B5D;
$gray-800: #414446;
$gray-900: #2B2D2F;
$gray-1000: #161717;

/**
 * ### Blue
 */
$platinum-400: #D6E2E3;
$platinum-500: #CCDBDC;
$mint-400: #AFDADD;
$mint-500: #9AD1D4;
$non-photo-blue-400: #99D8DF;
$non-photo-blue-500: #80CED7;
$cerulean-400: #00B1ED;
$cerulean-500: #007EA7;
$prussian-blue-400: #006D9F;
$prussian-blue-500: #003249;
$blue-500: #3066BE;
$blue-600: #265299;
$blue-700: #1D3E73;
$blue-800: #13294D;
$blue-900: #0A1526;

/**
 * ### Blue/Gray
 */

$blue-gray-140: #57595f;
$blue-gray-160: #41434a;
$blue-gray-200: #31416e;
$blue-gray-300: #2f3347;
$blue-gray-400: #2a385c;
$blue-gray-700: #1c2131;
$blue-gray-800: #1c2037;
$blue-gray-900: #10151D;
$blue-gray-1000: #13151b;

/**
 * ### Brown
 */
$licorice: #1F1300;

$marketing-pink-100: #d24ff5;

$marketing-blue-gray-200: #EAECF7;

$marketing-purple-50: #c4aaef;
$marketing-purple-300: #8000ff;
$marketing-purple-500: #241f46;

$marketing-blue-20: #F3F6FF;
$marketing-blue-100: #605dd4;
$marketing-blue-110: #6B5CFF;
$marketing-blue-150: #6053E5;
$marketing-blue-200: #5c54cf;
$marketing-blue-400: #5a4bca;
$marketing-blue-450: #5549CB;
$marketing-blue-500: #5341c4;
$marketing-blue-800: #1A1A43;

$marketing-blue-gray-100: #9789f5;
$marketing-blue-gray-600: #252933;
$marketing-blue-gray-700: #161626;
$marketing-blue-gray-800: #131326;
$marketing-blue-gray-900: #0F0F26;

$marketing-blue-black-500: #0B0B1B;


$marketing-turquoise-100: #81bdf9;
$marketing-turquoise-200: #81A4FF;

$marketing-green-100: #a2f7e1;

$marketing-gray-900: #111;

/**
 * ## Infinity Client Specific Colors
 */
$infinity-blue-900: #0e0e19;

$infinity-gray-100: #F0F1F2;
$infinity-gray-500: #E8EEFA;
$infinity-gray-700: #e6ebf8;
$infinity-gray-1000: #111128;