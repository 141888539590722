/**
 * # About Page Content Styles
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
.phoner {
    @extend %ellipse-background;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &__intro {
        @extend %container-intro;
        padding-bottom: 0;
        z-index: 2;
    }
}

.ladyfingers {
    @include ladyfingers-page-top();
}