/**
 * # Hero Section with Image
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/fonts" as *;
@use "../../styles/abstracts/mediaqueries" as *;
@use "../../styles/abstracts/utilities" as *;

/**
 * ## Styles
 */
.wrapper {
    color: $codGrey;
    padding-bottom: 50px;
    
    @include minSmMd {
        background: url('../../images/infinity/illustrations/hero_background.png') no-repeat center bottom;
        background-size: contain;
    }

    @include maxSm {
        background: url('../../images/infinity/illustrations/hero_background_mobile.png') no-repeat center 115%;
        background-size: contain;
        padding-bottom: 80px;
    }

    @include minXsmMaxSm {
        padding-bottom: 120px;
    }
}

.body {
    @extend %centerwrapper;
    padding-top: 114px; /* Computed height of `.page-header` */

    @include minSmMd {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 114px ($spacing * 10) 0;
    }
}

/**
 * ### Text Container
 */
.text-body {
    @include minSmMd {
        flex-shrink: 0;
        padding-top: 52px;
        margin-bottom: $spacing * 3;
    }

    @include minMd {
        padding-left: 10px;
    }
}

.hero-headline-main {
    @extend %headline-primary-infinity;

    margin-bottom: 0;

    @include maxSm {
        text-align: center
    }

    @media screen and (max-width: 390px) {
        font-size: 30px;
    }
}

.hero-headline-quinary {
    @extend %headline-quinary;
}

.headline-block {
    @extend %headline-block;
}

/**
 * ### Hero Image
 */
.hero-image-wrapper {
    max-width: 100%;

    @include minSmMd {
        width: 56%;
    }
}

.hero-image {
    margin-left: -4%;
    width: 116%;

    @include maxSm {
        width: 100%;
        margin-left: 0;
    }

    @include minSmMd {
        margin-left: -16%;
        width: 143%;
    }
}

// Slider

.slider {
    position: relative;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    min-height: 595px;
    margin-bottom: 30px;

    @include maxSm {
        min-height: auto;
        margin-bottom: 0;
    }
 }

 .slides {
    position: relative;
    width: 100%;
    display: flex;
    transition: transform 0.5s ease-in-out;
 }

 .slide {
    // position: absolute;
    width: 100%;
    min-width: 100%;
    // opacity: 0;
    transition: opacity 1s ease-in-out, position 1.2s ease-in-out;
    text-align: center;
    // transform: translateX(-100%);
    box-sizing: border-box;
    user-select: none;

    & svg {
        transform: translate(30px, 40px);

        @include maxSm {
            transform: translate(0, 40px);
            width: 100%;
            height: auto;
        }
    }

    &:first-child img {
        transform: translate(30px, 40px);
        width: 80%;
        object-fit: contain;

        @include maxSm {
            transform: translate(0, 40px);
            width: 100%;
        }
    }

    &--active {
        // opacity: 1;
        // position: relative;
        transform: translateX(0);
    }

    & img {
        display: block;
        margin: 0 auto;
        pointer-events: none;

       @include maxSmMd {
        max-width: 100%;
       }
    }

    @include maxSm {
        min-height: 299px;
    }
 }

 .pagination {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    z-index: 1;

    @include maxSm {
        position: relative;
        text-align: center;
        left: unset;
        bottom: unset;
        margin-top: 10px;
    }
}

.dot {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: rgba($color: $codGrey, $alpha: 0.2);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.6s ease;

    &--active {
        background-color: $codGrey;
    }
}