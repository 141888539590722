/**
 * # CTA Button
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/utilities" as *;
@use "../../styles/abstracts/fonts" as *;

/**
 * ## Styles
 */
 .cta-button {
    background-color: transparent;
    border-radius: $spacing * 10;
    display: inline-block;
    margin-bottom: $spacing * 8;
    overflow: hidden;
    padding: 1px;
    position: relative;
    z-index: 1;

    &::after {
        animation: rotate 7s linear infinite;
        background-color: transparent;
        background-image: repeating-conic-gradient(
                transparent 0%,
                transparent 20%,
                #e6c0ff 45%,
                #85629c 50%,
                rgba(133, 98, 156, 0) 60%,
                transparent 100%
            );
        content: "";
        display: block;
        left: -130%;
        height: 400%;
        position: absolute;
        top: -200%;
        width: 360%;
        z-index: 2;
    }

    &:hover {
        .cta-button__body {
            background-color: $blue-gray-160;
        }
    }

    &:active {
        .cta-button__body {
            background-color: $blue-gray-140;
        }
    }

    &--infinity {
        padding: 2px;
        border-radius: 32px;
        @include interaction-arrow;

        &::after {
            // background-image: repeating-conic-gradient(
            //     transparent 0%,
            //     transparent 10%,
            //     #ff0095 65%,
            //     hsl(274, 53%, 46%) 70%,
            //     rgba(101, 102, 212, 0) 80%,
            //     transparent 100%
            // );
            background-color: $fuchsiaBlue;
            animation: none;
            background-image: none;
            // height: 500%;
        }

        &::before {
            // position: absolute;
            // right: $spacing * 4;
            // top: 50%;
            // transform: translateY(-50%);
            display: none;
        }

        &:hover {
            .cta-button__body {
                background-color: transparent;
            }
        }
    
        &:active {
            .cta-button__body {
                background-color: transparent;
            }
        }
    }

    &--disabled {
        &:hover {
            cursor: not-allowed;
        }
    }

    &__body {
        background-color: $blue-gray-300;
        border-radius: $spacing * 10;
        color: $white;
        font-family: "Poppins Light", Helvetica, Arial, sans-serif;
        font-style: normal;
        display: inline-block;
        padding: ($spacing * 5) 46px ($spacing * 5) ($spacing * 5);
        position: relative;
        transition: background-color .25s ease-in;
        z-index: 3;

        .cta-button--infinity & {
            background-color: transparent;
            font-family: $font-family-infinity;
            padding: 10px 14px;

            &::after, &::before {
                display: none;
            }
        }

        .cta-button--disabled & {
            padding-right: ($spacing * 5);
            color: $gray-500;
        }

        .cta-button--image-label & {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 65px;
            min-width: 158px;
            padding: 0;
        }

        &::after {
            background-color: $white;
            content: "";
            display: block;
            height: 24px;
            position: absolute;
            right: $spacing * 5;
            top: $spacing * 5;
            transform:
                rotate(90deg)
                scale(1.1)
            ;
            width: 24px;
            -webkit-mask: url(../../images/icons/arrow.svg) center center no-repeat;
            mask: url(../../images/icons/arrow.svg) center center no-repeat;

            .cta-button--disabled &,
            .cta-button--image-label & {
                display: none;
            }

            .cta-button--infinity & {
                display: none;
            }
        }
    }
}

/**
 * ### Animations
 */
@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}