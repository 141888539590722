/**
 * ## Imports
 */
@use "./colors" as *;
@use "./measures" as *;
@use "./mediaqueries" as *;

/**
 * ## Font Families
 */
$font-family: "Poppins", Helvetica, Arial, sans-serif;
$font-family-infinity: "LexendDeca", Helvetica, Arial, sans-serif;

/**
 * ## Headlines
 */
%headline-primary {
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    font-size: 42px;
    line-height: 64px;
    margin: ($spacing * 4) 0;

    @include minSm {
        font-size: 64px;
        line-height: 96px;
        margin: ($spacing * 6) 0;
    }

    /**
     * ## Headline Primary - Infinity Client
     */
    &-infinity {
        font-family: $font-family-infinity;
        -webkit-font-smoothing: antialiased;
        font-size: 32px;
        line-height: 34px;
        margin: ($spacing * 4) 0;

        @include minSm {
            font-size: 48px;
            margin: ($spacing * 6) 0;
        }
    }
}

%headline-secondary {
    font-size: 32px;
    line-height: 48px;
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;

    @include minSm {
        font-size: 64px;
        line-height: 96px;
    }

    &-infinity {
        font-family: $font-family-infinity;
        -webkit-font-smoothing: antialiased;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        font-weight: 600;

        @include minSm {
            font-size: 32px;
            line-height: 40px;
        }
    }
}

%headline-tertiary {
    font-size: 24px;
    line-height: 36px;
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    margin: ($spacing * 2) 0;

    @include minSm {
        font-size: 42px;
        line-height: 63px;
        margin: $spacing 0 ($spacing * 4);
    }
}

%headline-quaternary {
    color: $white;
    font-size: 32px;
    line-height: 40px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;

    @include minSm {
        margin-bottom: 0;
    }
}

%headline-quinary {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    font-weight: 400;
    font-family: $font-family-infinity;
    -webkit-font-smoothing: antialiased;
    color: $codGrey;
    opacity: 50%;
}

/* - Not yet defined by design. */
// %headline-quinary {

// }

// %headline-senary {

// }

%headline-block {
    font-size: 10px;
    letter-spacing: .8rem;
    line-height: 23px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
}

%headline-inconsistent {
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 ($spacing * 4);

    @include minSm {
        font-size: 42px;
        line-height: 63px;
        margin: ($spacing * 6) 0;
    }
}

%paragraph-lean {
    color: rgba($white, .7);
    font-family: "Poppins Light", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 26px;
}

%paragraph-bright {
    color: $white;
    font-family: "Poppins Light", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 31px;
    margin-bottom: $spacing * 6;
}

%paragraph-thin {
    color: $white;
    font-family: "Poppins Thin", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 31px;
}