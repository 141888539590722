/**
 * # Borders
 */

/**
 * ## Imports
 */
@use "./measures" as *;

/**
 * ## Border Radius
 */
$border-radius-s: $measure;
$border-radius-m: $measure * 2;
$border-radius-l: $measure * 3;
$border-radius-xl: $measure * 4;
$border-radius-xxl: $measure * 10;
$border-radius-xxxl: $measure * 15;
$border-radius-xxxxl: $measure * 30;