/**
 * # Promo Box List
 */

/**
 * ## Imports
 */
@use "../../../../styles/abstracts/colors" as *;
@use "../../../../styles/abstracts/measures" as *;
@use "../../../../styles/abstracts/mediaqueries" as *;
@use "../../../../styles/abstracts/borders" as *;

/**
 * ## Styles
 */
.promo-box-list {
    column-gap: $spacing * 2;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $spacing * 2;
    width: 100%;

    @include minSm {
        grid-template-columns: 1fr 1fr;
    }

    &__item {
        background: rgba($black, .2);
        border-radius: $border-radius-xl;
        color: $white;
        padding: 30px;
    }

    &__icon {
        margin-bottom: $spacing * 2;
    }

    &__label {
        display: block;
        width: 100%;
    }
}