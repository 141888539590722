/**
 * # Button Close
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
.button {
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: $spacing * 9;
    justify-content: center;
    position: relative;
    width: $spacing * 9;

    &::before,
    &::after {
        background-color: rgba($white, .5);
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        transition: background-color .25s ease-in;
        width: $spacing * 4;
    }

    &:hover::before,
    &:hover::after {
        background-color: rgba($white, .8);
    }

    &::before {
        top: 9px;
        transform: translateY(8px) rotate(45deg);
    }

    &::after {
        bottom: 9px;
        transform: translateY(-8px) rotate(-45deg);
    }

    &:global(.button-close-cookies) {
        position: absolute;
        right: -12px;
        top: -40px;

        @include minSm {
            top: -12px;
        }
    }

    &--with-label {
        line-height: 1;
        padding-right: $spacing * 9;

        &::before,
        &::after {
            right: 0;
        }
    }
}

.label {
    color: $blue-gray-140;
    transition: color .25s ease-in;

    .button:hover &,
    .button:focus &,
    .button:active & {
        color: rgba($white, .8);
    }
}