/**
 * # Custom Browser Solutions
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;
 @use "../../styles/abstracts/fonts" as *;

/**
 * ## Styles
 */
 .wrapper {
    @extend %centerwrapper;
    font-family: $font-family-infinity;
 }

 .slider {
    position: relative;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    min-height: 660px;
 }

 .slides {
    position: relative;
    width: 100%;
    display: flex;
    transition: transform 0.5s ease-in-out;
 }

 .slide {
    display: flex;
    padding-top: 81px;
    padding-left: 40px;
    gap: 48px;
    width: 100%;
    min-width: 100%;
    transition: opacity 1s ease-in-out;
    user-select: none;

    &--active {
        transform: translateX(0);
    }

    @include maxSm {
        flex-direction: column;
        gap: 25px;
        padding-top: 24px;
        padding-left: 0;
    }

    @include maxSmMd {
        padding-left: 0;
    }

    &--info {
        padding-top: 10%;

        @include maxSm {
            padding-top: 0;
        }
    }

    &--card {
        border: 2px solid rgba($color: #000000, $alpha: 0.07);
        background-color: $whitePointer;
        padding: 44px 39px 60px 39px;
        border-radius: 16px;

        @include minMd {
            min-width: 553.07px;
        }

        @include maxSm {
            padding: 24px 16px;
        }

        &-title {
            font-size: 20px;
            line-height: 25px;
            font-weight: 400;
            margin: 0;


            @include minSm {
                font-size: 22px;
                line-height: 27.5px;
                margin-top: 20px;
                margin-bottom: 8px;
            }
        }

        &-text {
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            color: rgba($color: $codGrey, $alpha: 0.6);
            width: 100%;
            margin-top: 0;

            & img {
                margin-bottom: -5px;
            }

            @include maxSm {
                margin-top: 10px;
            }
        }
    }

    &-headline-quinary {
        @extend %headline-quinary;
        text-align: left;
        margin-bottom: 0;

        @include maxSm {
            margin-top: 0;
        }
    }

    &-teaser-text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $codGrey;
        opacity: 80%;
        max-width: 92%;
        margin-top: 12px;

        @include minSm {
            font-size: 24px;
            line-height: 35px;
        }
    }
 }

 .pagination {
    position: absolute;
    z-index: 1;
    left: 40px;
    bottom: 50px;

    @include maxSm {
        position: relative;
        text-align: center;
        left: unset;
        bottom: unset;
        margin-top: 10px;
    }
}

.dot {
    height: 12px;
    width: 12px;
    margin: 0 6px;
    background-color: rgba($color: $codGrey, $alpha: 0.2);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.6s ease;

    &--active {
        background-color: $codGrey;
    }
}

 .client {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include maxSmMd {
        flex-direction: column;
        align-items: flex-start;
    }

    @include maxSm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    &-name {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: $codGrey;
        margin-bottom: 5px;

        @include minSm {
            font-size: 20px;
            line-height: 25px;
        }
    }

    &-role {
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        color: $codGrey;
        opacity: 60%;
        margin-top: 0;
    }

    &-logo {
        max-width: 132px;
    }
 }

 .companies {
    margin-top: 41px;
    margin-bottom: 108px;
    

    @include maxSm {
        margin-bottom: 79px;
    }

    &-quinary {
        @extend %headline-quinary;
    }

    &--list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & img {
            width: 80%;
        }

        @include maxSm {
            display: block;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            box-sizing: border-box;

            &>div {
                display: inline-block;
                padding-left: 10%;
                vertical-align: middle;
                animation: marquee 15s linear infinite;
            }
        }
    }
 }

 @keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-500%);
    }
}
