/**
 * # Custom Browser Solutions
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;

/**
 * ## Styles
 */
 .wrapper {
    background: $white;
    margin-top: -3%;
    
    @include maxSm {
      margin-top: -15%;
      background-color: transparent;
    }
 }

 .body {
    @extend %centerwrapper;

    @include maxSm {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include minSm {
      padding-right: 0;
    }
 }

 .features-headline-main {
    @extend %headline-secondary-infinity;
    
    @include minSm {
        max-width: 30%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }

    @include maxSmMd {
      max-width: 45%;
    }

    @include maxSm {
      max-width: 100%;
    }
 }

 .headline-quinary {
    @extend %headline-quinary;
 }

 .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 16px;

    &--mobile {
      display: none;

      @include maxSmMd {
         display: flex;
         flex-direction: column;
         gap: unset;
      }
    }

    &--desktop {
      @include maxSmMd {
         display: none;
      }
    }
 }

 .column {
    flex: 32%;
    max-width: 32%;

    @include maxSmMd {
      width: 100%;
      max-width: 100%;
    }
 }

 .extensions {
    padding-bottom: 20px;

    & p {
        margin-bottom: 22px;
    }

    @include maxSm {
      & h3 {
         font-size: 16px;
      }
    }
 }

 .new-tab {
    padding-left: 22px;
    padding-right: 22px;

    & p {
        margin-bottom: 10px;
    }
 }

 .default-search {
    padding-left: 20px;
    padding-right: 20px;

    & h3 {
        width: 90%;
        margin: 0 auto;

        @include maxSm {
            font-size: 16px;
       }
    }

    & p {
        margin-bottom: 0;
    }
 }

 .engagement {
    padding-left: 17px;
    padding-right: 17px;

    & p {
        width: 80%;
        margin: 12px auto;
        margin-bottom: 18px;
    }
 }

 .onboarding {
    & img {
        width: 80%;
        display: block;
        margin: 0 auto;
        margin-top: -2px;
        padding-bottom: 2px;
    }
 }

 .theme {
    & p {
        margin-bottom: 0;
    }
 }

 .pink-border {
    &::before {
        background: linear-gradient(180deg, #EF60AC 0%, rgba(239, 96, 172, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
        rotate: 180deg;

        @include maxSm {
         background: linear-gradient(263.56deg, #EF60AC 3%, rgba(239, 96, 172, 0) 94.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
        }
    }
 }

 .last-mobile-card {
   margin-bottom: 0;
 }