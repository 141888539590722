/**
 * # Menu
 */
/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/fonts" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/mediaqueries" as *;

/**
 * # Styles
 */
.menu {
    color: $white;
    display: flex;
    flex-shrink: 0;
    gap: $spacing * 5;
}

:global(.menu--footer) {
    display: block;
    margin: ($spacing * 3) 0 ($spacing * 8);
    text-align: center;
    width: 100%;

    @include minSm {
        display: flex;
        justify-content: center;
        gap: $spacing * 4;
        margin-bottom: ($spacing * 12);
    }
}

:global(.menu--infinity-footer) {
    margin-top: $spacing;
    margin-bottom: 0;
    padding-bottom: $spacing;
    width: 15%;
    flex-direction: column;
    gap: 0;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    @include maxSm {
        padding-bottom: 0;
        width: 35%;
        & .list-item {
            padding-bottom: 0;
            padding-top: 0;
        }

        & .list-item:last-child {
            border-bottom: none;
        }
    }

    @include maxSmMd {
        width: 35%;
    }
}

.list-item {
    font-size: 16px;
    padding-top: 22px;

    :global(.menu--infinity-footer) & {
        font-family: $font-family-infinity;
        font-size: 16px;
        line-height: 48px;
        font-weight: 400;
        margin-top: 0;
        text-align: left;
        padding-top: 0;
        color: $white;
    }

    @include minSm {
        :global(.menu--infinity-footer) &:last-child>strong, :global(.menu--infinity-footer) &:last-child>a  {
            min-width: 70px;
        }
    }

    &__has-children {
        position: relative;

        &__icon {
            display: inline-block;
            position: relative;
            transform: rotate(-90deg);
        }

        &:hover {
            .children-menu {
                display: block;
            }
        }

        &:hover>&__icon {
            transform: rotate(0);
        }
    }
}

.link {
    color: $white;

    :global(.infinity) & {
        color: $codGrey;
        font-family: $font-family-infinity;
        font-weight: 400;
    }

    :global(.menu--infinity-footer) & {
        color: $white;
    }

    :global(.menu--footer) & {
        display: inline-block;
        min-width: 140px;
        padding-top: 5px;
    }

    :global(.menu--footer) &--forbidden {
        color: $gray-500;
    }

    &--forbidden {
        color: $gray-300;
        text-wrap: nowrap;
    }

    &--active {
        color: rgba($white, .75);
    }
}

.children-menu {
    list-style-type: none;
    background-color: #04040B;
    padding: ($spacing * 2) ($spacing * 4);
    padding-right: $spacing * 3;
    min-width: 246px;
    width: 100%;
    position: absolute;
    bottom: -80px;
    left: -20px;
    display: none;


    &__item-link {
        font-size: 16px;
        line-height: 66px;
        font-weight: 400;
        color: $white;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__icon {
            transform: rotate(-90deg);
        }
    }
}