/**
 * # Custom Browser Solutions
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;
 @use "../../styles/abstracts/fonts" as *;

/**
 * ## Styles
 */
 .mission {
    background: $fuchsiaBlue;
    color: $white;
    padding-top: $spacing * 21.5;
    padding-bottom: $spacing * 22.75;
    margin-top: $spacing * 23;

    @include maxSm {
        margin-top: 41px;
        padding-top: 24px;
        padding-bottom: 21px;
    }

    &--body {
        @extend %centerwrapper;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & div {
            width: 50%;
            padding-left: 38px;

            @include maxSm {
                width: 100%;
                padding-left: 16px;
            }

            &:last-child {
                padding-top: 20px;
            }
        }

        @include maxSm {
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-headline-secondary {
        @extend %headline-secondary-infinity;
    
        margin-bottom: 0;
        margin-top: 0;
        text-align: left;

        @include maxSm {
            font-size: 32px;
            line-height: 40px;
        }
    }
    
    &-headline-quinary {
        @extend %headline-quinary;

        text-align: left;
        color: $white;
        opacity: 50%;
    }

    &--button {
        background: $white;
        color: $fuchsiaBlue;
        margin-top: 17px;

        &::after {
            background-color: $white;
            color: $fuchsiaBlue;
        }

        & span {
            color: $fuchsiaBlue;
        }

        @include maxSm {
            margin-bottom: 0;
        }
    }

    &--body-text {
        opacity: 70%;
        font-family: $font-family-infinity;
        font-size: 16px;
        line-height: 26px;
        width: 90%;

        @include maxSm {
            margin-top: 0;
            width: 95%;
        }
    }
 }