/**
 * # Deco Box Text
 */

/**
 * ## Imports
 */
@use "../../styles/abstracts/colors" as *;
@use "../../styles/abstracts/measures" as *;
@use "../../styles/abstracts/borders" as *;
@use "../../styles/abstracts/mediaqueries" as *;
@use "../../styles/abstracts/utilities" as *;

/**
 * ## Styles
 */
.deco-box-text {
    background:
        url(../../images/decals/popart_comet_01.svg) no-repeat,
        url(../../images/decals/popart_comet_02.svg) no-repeat,
        linear-gradient($blue-gray-900, $blue-gray-900)
    ;
    background-position:
        50px 20px,
        right 50px bottom 48px,
    ;
    background-size: 18% auto, 50% auto;
    border-radius: $border-radius-xxl;
    color: $white;
    text-align: center;
    margin: 0 0 ($spacing * 15);
    max-width: 1100px;
    padding: ($spacing * 31) ($spacing * 10) ($spacing * 35);
    width: 100%;
    @include center-x;

    @include minXsm {
        background:
            url(../../images/decals/popart_comet_01.svg) no-repeat,
            url(../../images/decals/popart_comet_02.svg) no-repeat,
            url(../../images/decals/popart_comet_03.svg) no-repeat,
            linear-gradient($blue-gray-900, $blue-gray-900)
        ;
        background-position:
            70px 90px,
            right 100px top 60px,
            right 115px bottom 70px
        ;
        padding-left: ($spacing * 40);
        padding-right: ($spacing * 40);
    }

    @include minSm {
        margin-bottom: $spacing * 25;
        padding: ($spacing * 24) ($spacing * 50) ($spacing * 32);
    }

    @include minSmMd {
        padding-left: ($spacing * 70);
        padding-right: ($spacing * 70);
    }

    &__headline-inconsistent {
        @extend %headline-inconsistent;
        margin-bottom: 14px;
    }
}