/*
 * # Media queries / breakpoints
 */
/**
 * ## Imports
 */
@use "./variables" as *;

/**
 * ## Mixins
 */
 @mixin maxXXsm {

    /* 375px */
    @media (max-width: #{$screen-max-xxsm}) {
        @content;
    }
}

@mixin minXXsm {

    /* 376px */
    @media (min-width: #{$screen-min-xsm}) {
        @content;
    }
}

@mixin maxXsm {

    /* 575px */
    @media (max-width: #{$screen-max-xsm}) {
        @content;
    }
}

@mixin minXsm {

    /* 576px */
    @media (min-width: #{$screen-min-xsm}) {
        @content;
    }
}

@mixin maxSm {

    /* 759px */
    @media (max-width: #{$screen-max-sm}) {
        @content;
    }
}

@mixin minXsmMaxSm {

    /* 576px - 759px */
    @media (min-width: #{$screen-min-xsm}) and (max-width: #{$screen-max-sm}) {
        @content;
    }
}

@mixin minSm {

    /* 760px */
    @media (min-width: #{$screen-min-sm}) {
        @content;
    }
}

@mixin minSmMaxMd {

    /* 760px - 1032 */
    @media (min-width: #{$screen-min-sm}) and (max-width: #{$screen-max-sm-md}) {
        @content;
    }
}

@mixin maxSmMd {

    /* 1023px */
    @media (max-width: #{$screen-max-sm-md}) {
        @content;
    }
}

@mixin minSmMd {

    /* 1024px */
    @media (min-width: #{$screen-min-sm-md}) {
        @content;
    }
}

@mixin maxMd {

    /* 1161px */
    @media (max-width: #{$screen-max-md}) {
        @content;
    }
}

@mixin minMd {

    /* 1162px */
    @media (min-width: #{$screen-min-md}) {
        @content;
    }
}

@mixin maxMdLg {

    /* 1579px */
    @media (max-width: #{$screen-max-md-lg}) {
        @content;
    }
}

@mixin minMdLg {

    /* 1580px */
    @media (min-width: #{$screen-max-md-lg}) {
        @content;
    }
}

@mixin minLg {

    /* 1680px */
    @media (min-width: #{$screen-min-lg}) {
        @content;
    }
}