/**
 * # SCSS Variables
 */
$screen-max-xxsm: 375px;
$screen-min-xxsm: 376px;
$screen-max-xsm: 575px;
$screen-min-xsm: 576px;
$screen-max-sm: 759px;
$screen-min-sm: 760px;
$screen-max-sm-md: 1023px;
$screen-min-sm-md: 1024px;
$screen-max-md: 1161px;
$screen-min-md: 1162px;
$screen-max-md-lg: 1579px;
$screen-min-md-lg: 1580px;
$screen-max-lg: 1679px;
$screen-min-lg: 1680px;