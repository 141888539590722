/**
 * # Custom Browser Page
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/fonts" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;

 .features-wrap {
   position: relative;
   z-index: 0;

   &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 499px;
      transform: translateY(-55%);
      background: linear-gradient(180deg, #EBEDFF 4.24%, rgba(235, 237, 255, 0) 100.05%);
      z-index: -1;

      @include maxSm {
         transform: translateY(-25%);
      }
   }
 }

 .centerwrapper {
   font-family: $font-family-infinity;
   display: flex;
   padding-top: $spacing * 15;

   @include maxSm {
      flex-direction: column;
      padding-top: 24px;
      padding-left: 20px;
      padding-right: 20px;
   }

   @include minSmMaxMd {
      width: 100%;
   }

   &>div {
      &:first-child {
         width: 35%;

         @include maxSm {
            width: 100%;
         }

         @include minSmMaxMd {
            width: 40%;
         }
      }

      &:last-child {
         width: 65%;

         @include maxSm {
            width: 100%;
         }
      }
   }
 }

 :global(.headline-quinary) {
   text-align: left;
 }

 .headline-main {
   font-size: 24px;
   line-height: 30px;
   font-weight: 600;
   color: $codGrey;
   margin-top: 0;
   width: 90%;

   @include maxSm {
      text-align: left;
   }

   @include minSm {
      font-size: 32px;
      line-height: 40px;
   }
 }

 .paragraph {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: $codGrey;
    opacity: 60%;
    width: 78%;

    @include maxSm {
      width: 100%;
   }
 }

 .sonic-theme-slider {
   display: grid;
   place-items: center;
   z-index: 1;
   position: relative;
   margin-top: 57px;
   margin-bottom: 57px;

   @include maxSm {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 24px;
      margin-bottom: 0;
   }
 }

 .slider-container {
   display: grid;
   place-content: center;
   position: relative;
   transition: 0.4s all ease-out;
   --position: 20%;

   & *, & *::after, & *::before {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
   }
 }

 .image-container {
   max-width: 772px;
   max-height: 546px;
   height: auto;
   border-radius: 16px;
   overflow: hidden;

   @include maxSm {
      border-radius: 8px;
   }
 }

 .slider-image {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: left;
   display: block;
   max-width: 100%;
 }

 .image-left {
   position: absolute;
   inset: 0;
   width: var(--position);
   height: calc(100% + 1px);
   border-top-left-radius: 16px;
   border-bottom-left-radius: 16px;

   @include maxSm {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
   }
 }

 .slider {
   position: absolute;
   inset: 0;
   cursor: pointer;
   opacity: 0;
   /* for Firefox */
   width: 100%;
   height: 100%;

   &:focus-visible ~ &-button {
      outline: 5px solid black;
      outline-offset: 3px;
   }

   &-line {
      position: absolute;
      inset: 0;
      width: .2rem;
      background-color: #929292;
      /* z-index: 10; */
      left: var(--position);
      transform: translateX(-50%);
      pointer-events: none;
      top: -26px;
      bottom: -26px;

      @include maxSm {
         top: -10px;
         bottom: -10px;
      }
    }

    &-button {
      position: absolute;
      color: black;
      padding: 20px;
      border-radius: 100vw;
      display: grid;
      place-items: center;
      top: 50%;
      left: var(--position);
      transform: translate(-50%, -50%);
      pointer-events: none;

      &-img {
         width: 40px;
      }
    }
 }

