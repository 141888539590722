/**
 * # Custom Features
 */

/**
 * ## Imports
 */
 @use "../../styles/abstracts/colors" as *;
 @use "../../styles/abstracts/fonts" as *;
 @use "../../styles/abstracts/measures" as *;
 @use "../../styles/abstracts/borders" as *;
 @use "../../styles/abstracts/utilities" as *;
 @use "../../styles/abstracts/mediaqueries" as *;

 .wrapper {
    margin-top: 109px;
    margin-bottom: 0;
    text-align: center;
    background-color: $fuchsiaBlue;
    font-family: $font-family-infinity;
    padding-top: 98px;
    padding-bottom: 88px;

    @include maxSm {
      margin-top: 24px;
      padding-top: 24px;
      margin-bottom: 0;
      padding-bottom: 24px;
    }
 }

 .box {
    max-width: 694px;
    margin: 0 auto;
    text-align: center;
 }

 .quinary {
   @extend %headline-quinary;
   color: $white;
 }

 .headline {
   font-size: 32px;
   line-height: 40px;
   font-weight: 600;
   color: $white;
   text-align: center;
   margin-bottom: $spacing * 3;
   margin-top: $spacing * 3;
 }

 .cta-button {
   margin-top: 31px;
   margin-bottom: 0;

    & span {
      color: $fuchsiaBlue;
    }

    &::after {
      background-color: $white;
  }

 }

 .copy-text {
   font-size: 16px;
   line-height: 26px;
   font-weight: 400;
   color: $white;
   opacity: 60%;
   text-align: center;
   max-width: 79%;
   margin: 0 auto;

   @include maxSm {
    max-width: 99%;
    text-wrap: pretty;
   }
 }